import BusinessUsersService from "@/services/businessUsers/businessUsers.service";

export const groupsStoreModule = {
    namespaced: true,

    state: {
        driverGroups: null,
        driverGroupsFetch: null,
    },

    actions: {
        fetchDriverGroups({ state, commit }, params) {

            if(state.driverGroupsFetch) {
                return state.driverGroupsFetch;
            }

            let promise = BusinessUsersService.getDriverGroups(params)
                .then(
                    (response) => {
                        commit("SET_DRIVER_GROUPS", response.data?.items);
                        return Promise.resolve(response);
                    },
                )
                .catch(error => Promise.reject(error))
                .finally(() => commit("SET_PROMISE", {promise: null, field: "driverGroupsFetch"}))

            commit("SET_PROMISE", {promise: promise, field: "driverGroupsFetch"})

            return promise;
        },
    },

    mutations: {
        SET_DRIVER_GROUPS(state, data) {
            state.driverGroups = data;
        },
        SET_PROMISE(state, {promise, field}) {
            state[field] = promise
        },
    },

    getters: {
        GET_DRIVER_GROUPS(state) {
            return state.driverGroups;
        },
    },
};
