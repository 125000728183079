import BusinessUsersService from "@/services/businessUsers/businessUsers.service";
import { useLocalStorage } from "@/composition/useLocalStorage";

const { setLSItem, getLSItem } = useLocalStorage();

export const driversStoreModule = {
    namespaced: true,

    state: {
        drivers: [],
        loadingDrivers: false,
        driversWallet: null,
        driversWalletFetch: null,
        driversPagination: {
            perPage: 10,
            page: 1,
            total: 0,
            from: 0,
            to: 0,
        },
        driversFilters: {
            status: "",
            balanceType: "",
            group: "",
            search: "",
        }
    },

    actions: {
        async fetchDrivers({ state, commit }, {params, update = true}) {
            commit("SET_LOADING_DRIVERS", true);
            const cachedDrivers = JSON.parse(getLSItem("CACHED_DRIVERS"));

            if(cachedDrivers) {
                setTimeout(() => {
                    if (update) {
                        commit("SET_DRIVERS", cachedDrivers);
                    }
                    commit("SET_LOADING_DRIVERS", false);
                }, 500);
            }

            try {
                const response = await BusinessUsersService.getDrivers(params)
                const drivers = response?.data?.items ?? [];

                if (getLSItem("CACHED_DRIVERS") !== JSON.stringify(drivers)) {
                    commit("SET_DRIVERS", response.data?.items);
                    setLSItem("CACHED_DRIVERS", JSON.stringify(drivers));
                }

                commit("SET_LOADING_DRIVERS", false);

                return Promise.resolve(response)

            } catch (error) {
                return Promise.reject(error)
            }
        },
        fetchDriversWallet({ state, commit }, id) {
            if(state.driversWalletFetch) {
                return state.driversWalletFetch;
            }

            let promise = BusinessUsersService.getDriversAccounts(id)
                .then(
                    (response) => {
                        commit("SET_DRIVERS_WALLET", response.data);
                        return Promise.resolve(response);
                    },
                )
                .catch(error => Promise.reject(error))
                .finally(() => {
                    commit("SET_PROMISE", {promise: null, field: "driversWalletFetch"})
                })

            commit("SET_PROMISE", {promise: promise, field: "driversWalletFetch"})

            return promise;
        },
        async addDriver({state, commit}, data) {
            try {
                const response = await BusinessUsersService.addDriver(data);
                commit("ADD_DRIVER", response.data.item);
                return Promise.resolve();
            } catch (error) {
                return Promise.reject(error);
            }
        }
    },

    mutations: {
        SET_DRIVERS(state, data) {
            state.drivers = data;
        },
        SET_DRIVERS_WALLET(state, data) {
            state.driversWallet = data;
        },
        SET_PROMISE(state, {promise, field}) {
            state[field] = promise
        },
        SET_LOADING_DRIVERS(state, val) {
            state.loadingDrivers = val;
        },
        ADD_DRIVER(state, driver) {
            driver.balanceType = 1;
            driver.wallet = {
                accounts: [],
                isAllowSelfPay: false,
                typeBalance: 1,
            }
            state.drivers.unshift(driver);
        }
    },

    getters: {
        GET_DRIVERS(state) {
            return state.drivers;
        },
        GET_DRIVERS_FILTERED(state) {
            const filteredDrivers = state.drivers.filter(driver => {
                const { status, balanceType, group, search } = state.driversFilters;

                const statusMatch = !status || status === driver.status;
                const balanceTypeMatch = !balanceType || +balanceType === +driver.balanceType;
                const groupMatch = !group || driver.groups?.some(el => +el.id === +group);
                const searchNameMatch = !search || driver.fullName.toLowerCase().includes(search.toLowerCase());

                return statusMatch && balanceTypeMatch && groupMatch && searchNameMatch;
            })

            const startIndex = (state.driversPagination.page - 1) * state.driversPagination.perPage;
            const endIndex = startIndex + state.driversPagination.perPage;

            state.driversPagination.from = startIndex + 1;
            state.driversPagination.to = endIndex < filteredDrivers.length ? endIndex : filteredDrivers.length;
            state.driversPagination.total = filteredDrivers.length;

            return filteredDrivers.slice(startIndex, endIndex);
        },
        GET_DRIVERS_WALLET(state) {
            return state.driversWallet;
        },
        GET_DRIVERS_PAGINATION(state) {
            return state.driversPagination;
        },
        GET_DRIVERS_LOADING(state) {
            return state.loadingDrivers;
        },
        GET_DRIVERS_FILTERS(state) {
            return state.driversFilters;
        }
    },
};
