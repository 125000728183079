import store from "@/store";
import { computed } from "@vue/composition-api";
import {useStoreModule} from "@/composition/useStoreModule";
import discountStoreModule from "@/store/discounts/discountStoreModule";

const STORE_MODULE_NAME = 'app-discounts';
export function useDiscountTemplatesFetch() {

    const fetchDiscountTemplates = async () => {
       await store.dispatch(`${STORE_MODULE_NAME}/fetchDiscounts`);
    };

    const fetchAllDiscounts = async () => {
        await store.dispatch(`${STORE_MODULE_NAME}/fetchAllDiscounts`);
    };

    const discountTemplates = computed(() => {
        return store.getters[`${STORE_MODULE_NAME}/GET_TEMPLATES`];
    });

    const allDiscountTemplates = computed(() => {
        return store.getters[`${STORE_MODULE_NAME}/GET_ALL_DISCOUNTS`];
    });

    const discountTemplatesLoading = computed(() => {
        return store.getters[`${STORE_MODULE_NAME}/GET_TEMPLATES_LOADING`];
    })

    const discountTemplatesModified = computed(() => {
        const templates = store.getters[`${STORE_MODULE_NAME}/GET_TEMPLATES`];
        const customTemplateOption = {
            id: 0,
            issuers:[],
            title: "Видалити шаблон",
        };
        return templates?.length ? [customTemplateOption, ...templates] : [];
    });

    const mergedTemplates = computed(() => {
        const templates = store.getters[`${STORE_MODULE_NAME}/GET_TEMPLATES`] || [];
        const templatesAll = store.getters[`${STORE_MODULE_NAME}/GET_ALL_DISCOUNTS`] || [];
        // merging arrays leaving uniq id
        return [...templates, ...templatesAll].filter((item, index, self) => {
            return index === self.findIndex((i) => (
                i.id === item.id
            ));
        })
    });

    const getDiscountTemplatesTitle = (id) => {
        return mergedTemplates.value?.find(el => el.id === id)?.title;
    }

    useStoreModule(STORE_MODULE_NAME, discountStoreModule);

    return {
        discountTemplates,
        discountTemplatesModified,
        allDiscountTemplates,
        mergedTemplates,
        discountTemplatesLoading,

        fetchAllDiscounts,
        fetchDiscountTemplates,
        getDiscountTemplatesTitle,
    };
}