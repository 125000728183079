import store from '@/store';
import { computed } from '@vue/composition-api';
import { useStoreModule } from "@/composition/useStoreModule";
import { groupsStoreModule } from "@/store/businessUsers/groupsStore.module";

const STORE_MODULE_NAME = 'groupsStoreModule';

export function useDriverGroups() {

    useStoreModule(STORE_MODULE_NAME, groupsStoreModule);

    const fetchDriverGroups =  async (params) => {
        await store.dispatch(`${STORE_MODULE_NAME}/fetchDriverGroups`, params);
    };

    const driverGroups = computed(() => {
        return store.getters[`${STORE_MODULE_NAME}/GET_DRIVER_GROUPS`]
    })

    function getGroupTitle(id) {
        return driverGroups?.value?.find(el => parseInt(el.id) === parseInt(id))?.title || id
    }

    return { driverGroups, fetchDriverGroups, getGroupTitle };

}