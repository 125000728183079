import axiosIns from "@/libs/axios";

class BusinessUsersService {

    getDrivers = (params) => axiosIns.get('/business/drivers', { params });
    getDriversAccounts = (id) => axiosIns.get(`/business/drivers/${id}/accounts`);
    getDriverGroups = (params) => axiosIns.get('/business/driver-groups', { params });
    getManagers = (params) => axiosIns.get('/business/managers', { params });

    blockDriver = (id) => axiosIns.post(`/business/drivers/${id}/block`);
    unblockDriver = (id) => axiosIns.post(`/business/drivers/${id}/unblock`);
    changeIsAllowSelfPay = (id) => axiosIns.post(`/business/drivers/${id}`);
    addDriver = (data) => axiosIns.post('/business/drivers', data);
}

export default new BusinessUsersService();
