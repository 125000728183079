import FormOptionsService from "@/services/formOptions/formOptions.service";
import {onBeforeMount, reactive, toRefs} from "@vue/composition-api";

const targetTypesTranslations = {
    company: "Компанія",
    bundle: "Група",
    personal: "Персональна",
    all: "Всі",
    all_except_bundle: "Всі окрім груп",
};
export function useDiscountTargetTypes() {
    const data = reactive({
        targetTypes: null,
    })

    const fetchTargetTypes = async () => {
        const response = await FormOptionsService.targetTypes();
        response?.data?.items?.forEach(el => {
            el.translated = targetTypesTranslations[el.title]
        })
        data.targetTypes = response?.data.items
    }

    const getTargetTypesTitle = (id) => {
        const title = data.targetTypes?.find(el => el.value === id)?.title;
        return targetTypesTranslations[title];
    }

    onBeforeMount(async () => {
        await fetchTargetTypes()
    })

    return {...toRefs(data), getTargetTypesTitle};
}