import store from "@/store";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { useToast } from "vue-toastification/composition";
import {useStoreModule} from "@/composition/useStoreModule";
import discountStoreModule from "@/store/discounts/discountStoreModule";

export function useDiscountsTemplateChange() {
    const STORE_MODULE_NAME = 'app-discounts';
    const toast = useToast();
    function changeDiscountTemplate(params) {
        store.dispatch(`${STORE_MODULE_NAME}/changeComposite`, params)
            .then(() => {
                toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Все ок, але зміни вступають не відразу, додано завдання в обробку',
                        icon: 'CheckCircleIcon',
                        variant: 'success',
                    },
                })
            })
            .catch(() => {
                toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Помилка',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                    },
                })
            })
    }

    useStoreModule(STORE_MODULE_NAME, discountStoreModule);

    return { changeDiscountTemplate }
}