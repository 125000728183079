import store from '@/store'
import { computed } from '@vue/composition-api'
import { useStoreModule } from "@/composition/useStoreModule";
import { driversStoreModule } from "@/store/businessUsers/driversStore.module"
import BusinessUsersService from "@/services/businessUsers/businessUsers.service";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { useToast } from "vue-toastification/composition";

const STORE_MODULE_NAME = 'businessUsers';

export function useDrivers() {

    useStoreModule(STORE_MODULE_NAME, driversStoreModule);

    const toast = useToast();

    const fetchDrivers =  async (params, update) => {
        await store.dispatch(`${STORE_MODULE_NAME}/fetchDrivers`, {params, update});
    };

    const fetchDriversAccounts = async (id) => {
        await store.dispatch(`${STORE_MODULE_NAME}/fetchDriversWallet`, id);
    };

    const drivers = computed(() => {
        return store.getters[`${STORE_MODULE_NAME}/GET_DRIVERS`];
    });

    const driversFiltered = computed(() => {
        return store.getters[`${STORE_MODULE_NAME}/GET_DRIVERS_FILTERED`];
    });

    const driversPagination = computed(() => {
        return store.getters[`${STORE_MODULE_NAME}/GET_DRIVERS_PAGINATION`];
    });

    const driversWallet = computed(() => {
        return store.getters[`${STORE_MODULE_NAME}/GET_DRIVERS_WALLET`];
    });

    const driversLoading = computed(() => {
        return store.getters[`${STORE_MODULE_NAME}/GET_DRIVERS_LOADING`];
    })

    const driversFilters = computed(() => {
        return store.getters[`${STORE_MODULE_NAME}/GET_DRIVERS_FILTERS`];
    })


    function getDriverName(id) {
        return drivers?.value?.find(el => parseInt(el.profileId) === parseInt(id))?.fullName || id
    }

    function getDriverById(id) {
        return drivers?.value?.find(el => parseInt(el.profileId) === parseInt(id)) || null
    }

    async function blockDriver(id) {
        try {
            await BusinessUsersService.blockDriver(id);
            updateDriverStatus(id);
            await fetchDrivers({}, false);
        } catch (error) {
            toast({
                component: ToastificationContent,
                props: {
                    title: 'Error blocking driver',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                },
            })
        }
    }

    async function unblockDriver(id) {
        try {
            await BusinessUsersService.unblockDriver(id);
            updateDriverStatus(id);
            await fetchDrivers({}, false);
        } catch (error) {
            toast({
                component: ToastificationContent,
                props: {
                    title: 'Error unblocking driver',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                },
            })
        }
    }

    function updateDriverStatus(id) {
        const driver = getDriverById(id);
        driver.status = "ACCOUNT_PROCESSING";
    }

    async function addDriver(data) {
        try {
            await store.dispatch(`${STORE_MODULE_NAME}/addDriver`, data);
            return Promise.resolve();
        } catch (error) {
            return Promise.reject();
        }
    }

    return {
        drivers,
        driversWallet,
        driversFiltered,
        driversPagination,
        driversLoading,
        driversFilters,
        getDriverName,

        fetchDrivers,
        fetchDriversAccounts,
        blockDriver,
        unblockDriver,
        addDriver,
    };

}