import BusinessDiscounts from "@/services/businessDiscounts/businessDiscounts";

export default {
    namespaced: true,

    state: {
        templates: null,
        templatesFetching: null,
        discountChanges: null,
        discountChangesFetching: null,
        groupDiscounts: null,
        groupDiscountsFetch: null,
        allDiscounts: null,
        allDiscountsFetch: null,

    },

    actions: {
        fetchDiscounts({ state, commit }) {

            if(state.templatesFetching) {
                return state.templatesFetching;
            }

            let promise = BusinessDiscounts.templates()
                .then(
                    (response) => {
                        commit("FETCH_TEMPLATES_SUCCESS", response.data);
                        return Promise.resolve(response);
                    },
                )
                .catch(error => Promise.reject(error))
                .finally(() => commit("SET_TEMPLATES_FETCH", null))

            commit("SET_TEMPLATES_FETCH", promise)

            return promise;
        },

        fetchGroupDiscounts({ state, commit }, id) {

            if(state.groupDiscountsFetch) {
                return state.groupDiscountsFetch;
            }

            let promise = BusinessDiscounts.groupDiscounts(id)
                .then(
                    (response) => {
                        commit("SET_GROUP_DISCOUNTS", response.data);
                        return Promise.resolve(response);
                    },
                )
                .catch(error => Promise.reject(error))
                .finally(() => commit("SET_GROUP_DISCOUNT_FETCH", null))

            commit("SET_GROUP_DISCOUNT_FETCH", promise)

            return promise;
        },

        fetchDiscountChanges({ state, commit }, params) {

            if(state.discountChangesFetching) {
                return state.discountChangesFetching;
            }
            let promise = BusinessDiscounts.changes(params)
                .then(
                    (response) => {
                        commit("FETCH_DISCOUNT_CHANGES_SUCCESS", response.data);
                        return Promise.resolve(response);
                    },
                )
                .catch(error => Promise.reject(error))
                .finally(() => commit("SET_DISCOUNT_CHANGES_FETCH", null))

            commit("SET_DISCOUNT_CHANGES_FETCH", promise)

            return promise;
        },

        fetchAllDiscounts({ state, commit }) {

            if(state.allDiscountsFetch) {
                return state.allDiscountsFetch;
            }
            let promise = BusinessDiscounts.allDiscounts()
                .then(
                    (response) => {
                        commit("SET_ALL_DISCOUNTS", response.data);
                        return Promise.resolve(response);
                    },
                )
                .catch(error => Promise.reject(error))
                .finally(() => commit("SET_ALL_DISCOUNT_FETCH", null))

            commit("SET_ALL_DISCOUNT_FETCH", promise)

            return promise;
        },

        async changeComposite({state, commit}, params) {
            try {
                const response = await BusinessDiscounts.change(params)
                if (response.status === 200) {
                    return Promise.resolve(response)
                }
            }
            catch (error) {
                return Promise.reject(error);
            }
        },
    },

    mutations: {
        FETCH_TEMPLATES_SUCCESS(state, data) {
            state.templates = data;
        },
        SET_TEMPLATES_FETCH(state, promise) {
            state.templatesFetching = promise
        },
        FETCH_DISCOUNT_CHANGES_SUCCESS(state, data) {
            state.discountChanges = data;
        },
        SET_DISCOUNT_CHANGES_FETCH(state, promise) {
            state.discountChangesFetching = promise
        },
        SET_GROUP_DISCOUNTS(state, data) {
            state.groupDiscounts = data;
        },
        SET_GROUP_DISCOUNT_FETCH(state, promise) {
            state.groupDiscountsFetch = promise
        },
        SET_ALL_DISCOUNTS(state, data) {
            state.allDiscounts = data;
        },
        SET_ALL_DISCOUNT_FETCH(state, promise) {
            state.allDiscountsFetch = promise
        },
    },

    getters: {
        GET_TEMPLATES(state) {
            return state.templates?.items;
        },
        GET_DISCOUNT_CHANGES(state) {
            return state.discountChanges?.items;
        },
        GET_DISCOUNT_PAGINATION(state) {
            return state.discountChanges?.cursor;
        },
        GET_GROUP_DISCOUNTS(state) {
            return state.groupDiscounts?.items;
        },
        GET_ALL_DISCOUNTS(state) {
            return state.allDiscounts?.items;
        },
        GET_DISCOUNT_CHANGES_LOADING(state) {
            return !!state.discountChangesFetching;
        },
        GET_TEMPLATES_LOADING(state) {
            return !!state.templatesFetching;
        },
    },
};
