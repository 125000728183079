import axiosIns from "@/libs/axios";

class FormOptionsService {

    fetchingAccountsTypes = false;
    fetchingDiscountTargetTypes = false;
    fetchingTaskStatuses = false;
    fetchingManagersActionsTypes = false;
    fetchingManagersRoles = false;
    fetchingDriversStatuses = false;
    fetchingTransactionStatuses = false;

    accountsTypes() {

        if(this.fetchingAccountsTypes) {
            return this.fetchingAccountsTypes;
        }

        let promise = axiosIns.get('/forms/options/accounts/types/all');

        this.fetchingAccountsTypes = promise;

        return promise;
    };
    allowedAccountsTypes() {

        if(this.fetchingAllowedAccountsTypes) {
            return this.fetchingAllowedAccountsTypes;
        }

        let promise = axiosIns.get('/forms/options/accounts/types/allowed');

        this.fetchingAllowedAccountsTypes = promise;

        return promise;
    };

    targetTypes() {

        if(this.fetchingDiscountTargetTypes) {
            return this.fetchingDiscountTargetTypes;
        }

        let promise = axiosIns.get('/forms/options/discounts/targets/types');

        this.fetchingDiscountTargetTypes = promise;

        return promise;
    };

    tasksStatuses() {

        if(this.fetchingTaskStatuses) {
            return this.fetchingTaskStatuses;
        }

        let promise = axiosIns.get('/forms/options/tasks/statuses');

        this.fetchingTaskStatuses = promise;

        return promise;
    };

    managersActionsTypes() {
        if(this.fetchingManagersActionsTypes) {
            return this.fetchingManagersActionsTypes;
        }

        let promise = axiosIns.get('/forms/options/managers/actions/types');

        this.fetchingManagersActionsTypes = promise;

        return promise;
    };

    managersRoles() {
        if(this.fetchingManagersRoles) {
            return this.fetchingManagersRoles;
        }

        let promise = axiosIns.get('/forms/options/managers/roles');

        this.fetchingManagersRoles = promise;

        return promise;
    };
    driversStatuses() {
        if(this.fetchingDriversStatuses) {
            return this.fetchingDriversStatuses;
        }

        let promise = axiosIns.get('/forms/options/drivers/statuses');

        this.fetchingDriversStatuses = promise;

        return promise;
    };

    transactionStatuses() {
        if(this.fetchingTransactionStatuses) {
            return this.fetchingTransactionStatuses;
        }

        let promise = axiosIns.get('/forms/options/transactions/balances/simple-statuses');

        this.fetchingTransactionStatuses = promise;

        return promise;
    };
}

export default new FormOptionsService();