<template>
  <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
  >
    <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
        class="bg-white p-2"
    >
      <validation-provider
          v-if="targetTypes"
          #default="validationContext"
          name="targetType"
          :rules="{required: true}"
      >
        <b-form-group
            id="input-group-5"
            label="Ціль"
            label-for="targetType"
            :state="getValidationState(validationContext)"
        >
          <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              v-model="targetType"
              label="translated"
              :options="targetTypes"
              :reduce="val => val.value"
              name="tarhet"
              placeholder="Ціль"
              @input="val => handleTargetSelect(val)"
          />
          <b-form-invalid-feedback :state="getValidationState(validationContext)">
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>

      <validation-provider
          #default="validationContext"
          name="typeAccount"
          :rules="{required: true}"
      >
        <b-form-group
            v-if="allowedAccountTypes"
            id="input-group-1"
            label="Тип рахунку"
            label-for="typeAccount"
            :state="getValidationState(validationContext)"
        >
          <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              v-model="typeAccount"
              label="translated"
              :options="allowedAccountTypes"
              :reduce="val => val.value"
              name="typeAccount"
              placeholder="Оберіть аккаунт"
          />
        </b-form-group>
        <b-form-invalid-feedback :state="getValidationState(validationContext)">
          {{ validationContext.errors[0] }}
        </b-form-invalid-feedback>
      </validation-provider>

      <validation-provider
          #default="validationContext"
          name="templateIdSelect"
          :rules="{required: true}"
      >
        <b-form-group
            id="input-group-2"
            label="Шаблон"
            label-for="templateIdSelect"
            :state="getValidationState(validationContext)"
        >
          <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              v-model="templateId"
              label="title"
              :options="discountTemplatesModified"
              :reduce="val => val.id"
              name="templateIdSelect"
              placeholder="Оберіть шаблон"
          />
          <b-form-invalid-feedback :state="getValidationState(validationContext)">
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>

      <b-form-group
          id="input-group-3"
          label="Час призначення"
          label-for="applicationTime"
      >
        <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            v-model="applicationTimeSelect.value"
            label="title"
            :options="applicationTimeSelect.options"
            :reduce="val => val.value"
            name="applicationTime"
            placeholder="Час призначення"
        />
      </b-form-group>

      <validation-provider
          v-if="applicationTimeSelect.value === 'delayed'"
          #default="validationContext"
          name="applicationDate"
          :rules="{
            required: applicationTimeSelect.value === 'delayed',
          }"
      >
        <b-form-group
            id="input-group-4"
            label-for="applicationDate"
            :state="getValidationState(validationContext)"
        >
          <b-form-datepicker
              v-model="scheduledDate"
              :min="datepickerParams.currentDate"
              locale="UA"
          />
          <b-form-invalid-feedback :state="getValidationState(validationContext)">
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>

      <validation-provider
          v-if="applicationTimeSelect.value === 'delayed'"
          #default="validationContext"
          name="applicationTime"
          :rules="{
            required: applicationTimeSelect.value === 'delayed',
            timeValidate: scheduledDate,
          }"
      >
        <b-form-group
            id="input-group-4"
            label-for="applicationTime"
            :state="getValidationState(validationContext)"
        >
          <b-form-input id="applicationTime" type="time" v-model="scheduledTime" />
          <b-form-invalid-feedback :state="getValidationState(validationContext)">
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>



      <validation-provider
          v-if="optionArrays.profileIds"
          #default="validationContext"
          name="profileIds"
          :rules="{required: true}"
      >
        <b-form-group
            id="input-group-6"
            label="Оберіть водіїв"
            label-for="profileIds"
            :state="getValidationState(validationContext)"
        >
          <v-select
              multiple
              v-model="profileIds"
              label="fullName"
              :options="optionArrays.profileIds"
              :reduce="val => val.profileId"
              name="profileIds"
              placeholder="Водії"
          />
          <b-form-invalid-feedback :state="getValidationState(validationContext)">
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>

      <validation-provider
          v-if="optionArrays.bundleIds"
          #default="validationContext"
          name="bundleIds"
          :rules="{required: true}"
      >
        <b-form-group
            id="input-group-6"
            label="Оберіть групу"
            label-for="bundleIds"
            :state="getValidationState(validationContext)"
        >
          <v-select
              multiple
              v-model="bundleIds"
              label="title"
              :options="optionArrays.bundleIds"
              :reduce="val => val.id"
              name="bundleIds"
              placeholder="Група"
          />
          <b-form-invalid-feedback :state="getValidationState(validationContext)">
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>

      <validation-provider
          v-if="optionArrays.excludedProfileIds"
          #default="validationContext"
          name="excludedProfileIds"
      >
        <b-form-group
            id="input-group-6"
            label="За винятком"
            label-for="excludedProfileIds"
            :state="getValidationState(validationContext)"
        >
          <v-select
              multiple
              v-model="excludedProfileIds"
              label="fullName"
              :options="optionArrays.excludedProfileIds"
              :reduce="val => val.profileId"
              name="excludedProfileIds"
              placeholder="Вибрати для виключення"
          />
          <b-form-invalid-feedback :state="getValidationState(validationContext)">
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>

      <validation-provider
          v-if="optionArrays.excludedBundleIds"
          #default="validationContext"
          name="excludedBundleIds"
      >
        <b-form-group
            id="input-group-6"
            label="Всі окрім груп"
            label-for="excludedBundleIds"
            :state="getValidationState(validationContext)"
        >
          <v-select
              multiple
              v-model="excludedBundleIds"
              label="title"
              :options="optionArrays.excludedBundleIds"
              :reduce="val => val.id"
              name="excludedBundleIds"
              placeholder="Всі окрім груп"
          />
          <b-form-invalid-feedback :state="getValidationState(validationContext)">
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>

      <b-button
          @click.prevent="handleSubmit(onSubmit)"
          variant="primary"
      >
        Додати шаблон
      </b-button>

    </b-form>
  </validation-observer>
</template>

<script>

import { BForm, BFormGroup, BButton, BFormDatepicker, BFormInvalidFeedback, BFormInput} from "bootstrap-vue";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import { required } from "@core/utils/validations/validations";
import router from '@/router'

import {onBeforeMount, reactive, ref, toRefs} from "@vue/composition-api";

import { useAccountsTypes } from "@/composition/formOptions/useAccountsTypes";
import { useDiscountsTemplateChange } from "@/composition/discounts/useDiscountsTemplateChange";
import { useDiscountTemplatesFetch } from "@/composition/discounts/useDiscountTemplatesFetch";
import { useDiscountTargetTypes } from "@/composition/formOptions/useDiscountTargetTypes";
import { useDrivers } from "@/composition/businessUsers/useDrivers";
import { useDriverGroups } from "@/composition/businessUsers/useDriverGroups";
import formValidation from '@core/comp-functions/forms/form-validation'
import { useBusinessSettings } from "@/composition/businessSettings/useBusinessSettings";

const initialOptionArrays = {
  profileIds: null,
  bundleIds: null,
  excludedProfileIds: null,
  excludedBundleIds: null,
};

const initialFormParams = {
  templateId: null,
  typeAccount: null,
  targetType: null,
  scheduledDate: undefined,
  scheduledTime: new Date().toTimeString(),
  profileIds: null,
  bundleIds: null,
  excludedProfileIds: null,
  excludedBundleIds: null,
};
export default {
  components: {
    BForm,
    BFormGroup,
    BButton,
    BFormDatepicker,
    BFormInvalidFeedback,
    BFormInput,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  setup() {

    const form = reactive({...initialFormParams});
    const optionArrays = ref({...initialOptionArrays});

    const applicationTimeSelect = reactive({
      value: "now",
      options: [
        { title: 'Зараз', value: 'now' },
        { title: 'Відкладений час', value: 'delayed' },
      ]
    });

    const datepickerParams = {
      currentDate: new Date(),
      minTime: date => new Date(date.getHours(), date.getMinutes()),
    };

    let targetSelectValues = ref(null);

    const { allowedAccountTypes } = useAccountsTypes();
    const { discountTemplatesModified, fetchDiscountTemplates } = useDiscountTemplatesFetch();
    const { targetTypes } = useDiscountTargetTypes();
    const { changeDiscountTemplate } = useDiscountsTemplateChange();
    const { fetchDrivers, drivers } = useDrivers();
    const { fetchDriverGroups, driverGroups } = useDriverGroups();
    const { isEndUser } = useBusinessSettings();

    const { refFormObserver, getValidationState, resetForm } = formValidation(resetDiscountsForm);

    const clearOptionArrays = () => {
      optionArrays.value = {...initialOptionArrays}
    };
    const setOptionsArray = (name, arr) => {
      optionArrays.value[name] = arr;
    };

    const handleTargetSelect = async (targetId) => {
      await clearOptionArrays();
      switch(targetId) {
        //company
        case 1 : {
          break;
        }
        //personal
        case 3 : {
          await fetchDrivers({filterCanUseDriverCard: true});
          setOptionsArray("profileIds", drivers);
          break;
        }
        //bundle
        case 2 : {
          await fetchDriverGroups();
          setOptionsArray("bundleIds", driverGroups);
          break;
        }
        //all
        case 4 : {
          await fetchDrivers({filterGroupId: 0, filterCanUseDriverCard: true});
          setOptionsArray("excludedProfileIds", drivers);
          await fetchDriverGroups();
          setOptionsArray("excludedBundleIds", driverGroups);
          break;
        }
        //all_except_bundle
        case 5 : {
          await fetchDrivers({filterGroupId: 0, filterCanUseDriverCard: true});
          setOptionsArray("excludedProfileIds", drivers);
          break;
        }
      }
    };

    function resetDiscountsForm() {
      clearOptionArrays();
      Object.assign(form, initialFormParams);
    }

    const onSubmit = async () => {
      const options = {
        targetType: form.targetType,
        typeAccount: form.typeAccount,
        templateId: form.templateId,
        profileIds: form.profileIds,
        bundleIds: form.bundleIds,
        excludedProfileIds: form.excludedProfileIds,
        excludedBundleIds: form.excludedBundleIds,
      }
      if (applicationTimeSelect.value === "delayed") {
        options.scheduledAt = `${form.scheduledDate} ${form.scheduledTime}`;
      }
      await changeDiscountTemplate({...options});
      resetForm();
    };

    extend('timeValidate', {
      validate: (value, args) => {
        const {scheduledDate} = args;
        const now = new Date();
        const dateField = new Date(scheduledDate);

        const timeField = new Date(`${dateField.toDateString()} ${form.scheduledTime}`);

        return dateField.getDate() === now.getDate() ? timeField > new Date(now.getTime() + 10 * 60000) : true
      },
      params: ['scheduledDate'],
      message: 'Час повинен буди на 10 хвилин більше від теперішнього часу'
    });

    onBeforeMount(() => {
      if (isEndUser.value) {
        router.push({name: 'error-404'})
      }
      fetchDiscountTemplates()
    });

    return {
      ...toRefs(form),

      allowedAccountTypes,
      discountTemplatesModified,
      applicationTimeSelect,
      targetTypes,
      targetSelectValues,
      datepickerParams,
      optionArrays,

      refFormObserver,
      required,

      handleTargetSelect,
      onSubmit,
      getValidationState,
      resetForm,
    };

  },
};
</script>