import axiosIns from "@/libs/axios";

class BusinessDiscountsService {

    templates = () => axiosIns.get(`/business/discounts/templates`);
    groupDiscounts = (id) => axiosIns.get(`/business/driver-groups/${id}/discounts`);
    change = (data) => axiosIns.post(`/business/discounts/change/composite`, data);
    changes = (params) => axiosIns.get(`/business/discounts/changes`, { params });
    allDiscounts = (params) => axiosIns.get(`/forms/options/discounts`, { params });

}

export default new BusinessDiscountsService();
