import {onBeforeMount, reactive, toRefs} from '@vue/composition-api'
import FormOptionsService from "@/services/formOptions/formOptions.service";

const accountTypesTranslations = {
    cash: "Готівковий",
    cashless: "Безготівковий",
    both: "Обидва",
};
export function useAccountsTypes() {
    const data = reactive({
        accountsTypes: null,
        allowedAccountTypes: null,
    })

    const fetchAccountsTypes =  async () => {
        const response = await FormOptionsService.accountsTypes();
        response?.data?.items?.forEach(el => {
            el.translated = accountTypesTranslations[el.title]
        })
        data.accountsTypes = response?.data?.items;
    };

    const fetchAllowedAccountsTypes =  async () => {
        const response = await FormOptionsService.allowedAccountsTypes();
        response?.data?.items?.forEach(el => {
            el.translated = accountTypesTranslations[el.title]
        })
        data.allowedAccountTypes = response?.data?.items;
    };

    const getAccountsTypesTitle = (id) => {
        const title = data.accountsTypes?.find(el => el.value === id)?.title;
        return accountTypesTranslations[title];
    }

    onBeforeMount(async() => {
        await fetchAccountsTypes();
        await fetchAllowedAccountsTypes();
    });

    return {getAccountsTypesTitle, ...toRefs(data)};

}